import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
 
  html {
    background-color: #0F1624;
    font-family: Space Grotesk, sans-serif;
    background: "#0F1624",
  }
`;
